@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");

$white: #f7f9f9;
$black: #000;
$rgba-gray: rgba($black, 0.2);
$light-gray: #aeb6bf;
$very-dark-gray: #1b2631;
$dark-blue: #121331;
$green: #06c18a;
$alert-color: #e67e22;
$danger-color: #b03a2e;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
}

body {
  font-family: "Quicksand", sans-serif;
  background: $white;
  color: $very-dark-gray;

  &::-webkit-scrollbar {
    width: 1rem;
    height: 0;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $rgba-gray;
  }
}

body.dark {
  background: $very-dark-gray;
  color: $white;
}

a {
  color: inherit;
  text-decoration: none;
}

ul,
ol {
  list-style: none;
}

button {
  color: inherit;
}
