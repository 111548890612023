@font-face {
  font-family: "icomoon";
  src: url("fonts/icomoon.eot?ghnms1");
  src: url("fonts/icomoon.eot?ghnms1#iefix") format("embedded-opentype"),
    url("fonts/icomoon.ttf?ghnms1") format("truetype"),
    url("fonts/icomoon.woff?ghnms1") format("woff"),
    url("fonts/icomoon.svg?ghnms1#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-home:before {
  content: "\e900";
}
.icon-home2:before {
  content: "\e901";
}
.icon-home3:before {
  content: "\e902";
}
.icon-pencil:before {
  content: "\e905";
}
.icon-pencil2:before {
  content: "\e906";
}
.icon-quill:before {
  content: "\e907";
}
.icon-school:before {
  content: "\e91f";
}
.icon-library:before {
  content: "\e921";
}
.icon-tag:before {
  content: "\e935";
}
.icon-tags:before {
  content: "\e936";
}
.icon-wrench:before {
  content: "\e991";
}
.icon-hammer:before {
  content: "\e996";
}
.icon-bin:before {
  content: "\e9ac";
}
.icon-work:before {
  content: "\e9ae";
}
.icon-untaged:before {
  content: "\e9c9";
}
.icon-earth:before {
  content: "\e9ca";
}
.icon-checkbox-checked:before {
  content: "\ea52";
}
.icon-checkbox-unchecked:before {
  content: "\ea53";
}
.icon-twitter:before {
  content: "\ea96";
}
.icon-github:before {
  content: "\eab0";
}
.icon-linkedin:before {
  content: "\eac9";
}
.icon-linkedin2:before {
  content: "\eaca";
}
