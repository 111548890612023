.todo-input {
  background: $white;
  box-shadow: 0px 0px 5px 5px $rgba-gray;
  padding: 1rem;
  border-radius: 0.8rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;

  .label {
    font-size: 1.6rem;
    font-weight: bold;
    display: block;
    text-align: center;
  }

  .input-tag {
    display: flex;
    box-shadow: 0px 0px 5px 1px $rgba-gray inset;
    border-radius: 0.4rem;
    overflow: hidden;
    background: $white;

    .input {
      height: 3.5rem;
      width: 100%;
      display: block;
      background: none;
      border: none;
      padding-left: 1rem;
      padding-right: 0.5rem;
      outline: none;
      font-size: 1.4rem;
      font-weight: 500;
    }

    .tag {
      outline: none;
      width: 30%;
      border: none;
      padding-left: 0.5rem;
      background: $rgba-gray;
      border-radius: 0;
      font-size: 1.4rem;
      font-weight: 500;
    }
  }

  .button {
    background: $green;
    border: none;
    height: 3.5rem;
    font-size: 1.6rem;
    font-weight: bold;
    border-radius: 0.4rem;
    transition: 500ms;

    &:active {
      transform: scale(0.95, 0.9);
    }
  }
}

body.dark {
  .todo-input {
    background: $very-dark-gray;

    .input-tag {
      box-shadow: 0px 0px 5px 1px $very-dark-gray inset;
    }
  }
}
