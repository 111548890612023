.header {
  height: 6rem;
  display: flex;
  align-items: center;
  padding: 0 2rem;
  justify-content: space-between;
  position: relative;
  background: $white;
  border-bottom: 4px solid $rgba-gray;
  box-shadow: 0px 0px 5px 1px $rgba-gray;

  &__title {
    font-size: 3.2rem;
    font-weight: 700;
  }

  &__buttons {
    .dark-mode-button {
      position: relative;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      width: 4.6rem;
      height: 2.4rem;
      // padding: 0.5rem;
      border: none;
      background: $rgba-gray;
      border-radius: 1.2rem;

      &.active {
        .dark-mode-button__toggle {
          left: 2.3rem;
        }
      }

      &:active {
        .dark-mode-button__toggle {
          box-shadow: 0px 0px 5px 5px $green;
        }
      }

      @media screen and (min-width: 768px) {
        &:hover {
          .dark-mode-button__toggle {
            box-shadow: 0px 0px 5px 5px $green;
          }
        }
      }
      &__icon {
        font-size: 1.6rem;
        display: flex;
        align-items: center;
        line-height: 1.6rem;
      }

      &__toggle {
        width: 2rem;
        height: 2rem;
        background: $white;
        position: absolute;
        border-radius: 50%;
        transition: 250ms;
        top: 2px;
        left: 0.3rem;
      }
    }
  }

  &__burger-button {
    display: none;
  }
}

body.dark {
  .header {
    background: $very-dark-gray;
  }
}

//   burger-button {
//     width: 34px;
//     height: 30px;
//     display: flex;
//     flex-direction: column;
//     gap: 0.6rem;

//     div {
//       width: 100%;
//       height: 0.6rem;
//       border-radius: 0.3rem;
//       background: $green;
//       transition: 250ms;

//       &:first-of-type {
//         width: 50%;
//       }
//       &:last-of-type {
//         width: 50%;
//         margin-left: auto;
//       }
//     }

//     &.active {
//       div {
//         &:first-of-type {
//           transform: translate(2px, 5.5px) rotate(45deg);
//         }
//         &:nth-child(2) {
//           transform: rotate(-45deg);
//         }
//         &:last-of-type {
//           transform: translate(-2px, -5.5px) rotate(45deg);
//         }
//       }
//     }
//     @media screen and (min-width: 768px) {
//       display: none;
//     }
//   }
